<template>
  <div class="wrapper-title animation-block"
       v-bind:class="[designStore.getTitlePage.img_class, designStore.getDefinitionHeader ? 'wrapper-title-h' : '']"
       ref="titlePageContainer"
  >
    <div class="line-page"></div>
    <div class="inner-wrapper h-100">

      <div class="page-block-title">
        <div class="animation-block-title" id="animation-block-title">
          <h1
              class="page-title element-animation"
              v-bind:class="{ 'page-title-offset' : designStore.getTitlePage.btn_history }"
          >
            {{ designStore.getTitlePage.title }}</h1>
          <p
              v-if="designStore.getTitlePage.sub_title"
              class="page-sub-title element-animation"
          >
            {{ designStore.getTitlePage.sub_title }}</p>

          <router-link :to="{ name: 'Store'}">
            <button
                v-if="designStore.getTitlePage.btn_history"
                type="button"
                class="btn btn-alternate element-animation"
                :class="{ 'btn-offset' : designStore.getTitlePage.btn_history }"
            >
              {{ titlePageCms.fgBackToStore }}
            </button>
          </router-link>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from 'pinia'
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"

export default {
  name: "TitlePage",
  props: {
    titlePageCms: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  methods: {
    handleScroll() {
      let pageOffset = window.pageYOffset;
      let elem = document.getElementById("animation-block-title");
      let offset = pageOffset / 3;
      elem.style.transform = "translateY(" + offset + "px)";
    },
    watchForCmsContentChange() {
      this.cmsStore.$onAction(
          ({name, store, args, after, onError}) => {
            if (name === '_setComponentContent') {
              after(result => {
                this.setAllCmsComponentContent(result)
              })
            } else if (name === '_setPageContent') {
              after(result => {
                this.setAllCmsContent(result.content)
                this.setContentLoaded(true)
              })
            }
          })
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    const titlePageElement = this.$refs['titlePageContainer'];
    const height = titlePageElement.clientHeight ? titlePageElement.clientHeight : 300
    this.designStore.setHeightOfTitlePage(height)
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>


<style lang="scss">

body {
  &.faqs .hero-default-desktop {
    background-image: url('../assets/images/hero/FAQs_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/FAQs_Desktop.jpg');
    }
  }

  &.register-existing .bg-friend {
    background-image: url('../assets/images/hero/Invite_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/Invite_Desktop.jpg');
    }
  }

  &.invite-others .bg-friend {
    background-image: url('../assets/images/hero/Invite_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/Invite_Desktop.jpg');
    }
  }

  &.prayer .bg-friend {
    background-image: url('../assets/images/hero/Prayer_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/Prayer_Desktop.jpg');
    }
  }

  &.friend .bg-friend {
    background-image: url('../assets/images/hero/Friend_Home_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/Friend_Home_Desktop.jpg');
    }
  }

  &.witness .bg-friend {
    background-image: url('../assets/images/hero/Witnessing_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/Witnessing_Desktop.jpg');

    }
  }

  &.learn-about-friends .hero-default-desktop {
    background-image: url('../assets/images/hero/Learn_About_Friends_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/Learn_About_Friends_Desktop.png');
    }
  }

  &.learn-about-gideons .hero-default-desktop {
    background-image: url('../assets/images/hero/Gideons_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/Gideons_Desktop.jpg');
    }
  }

  &.privacy-policy .hero-default-desktop {
    background-image: url('../assets/images/hero/Privacy_Mobile.jpg');
    @media (min-width: $sm) {
      background-image: url('../assets/images/hero/Privacy_Desktop.jpg');
    }
  }
}

.bg-friend {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-image: url(../assets/images/bg-friend3.jpg);
}

.hero-default-desktop {
  position: relative;
  background-image: url(../assets/images/hero-default-desktop.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 100px;
  @include MQ(L) {
    margin-top: 0;
  }
}

.bg-ministry {
  background-image: url('../assets/images/hero/Minstry_Updates_Mobile.jpg');
  @media (min-width: $sm) {
    background-image: url('../assets/images/hero/Minstry_Updates_Desktop.jpg');
  }
}

.bg-ministry-update {
  background-image: url('../assets/images/hero/Ministry_Update_Mobile.jpg');
  @media (min-width: $sm) {
    background-image: url('../assets/images/hero/Ministry_Update_Desktop.jpg');
  }
}

.bg-cart {
  background-image: url('../assets/images/hero/Store_Mobile.jpg');
  @media (min-width: $sm) {
    background-image: url('../assets/images/hero/Store_Desktop.jpg');
  }
}

.bg-storeProduct {
  background-image: url('../assets/images/hero/Store_Mobile.jpg');
  @media (min-width: $sm) {
    background-image: url('../assets/images/hero/Store_Desktop.jpg');
  }
}

.bg-account-management {
  position: relative;
  background-image: url('../assets/images/hero/My_Account_Mobile.jpg');
  @media (min-width: $sm) {
    background-image: url('../assets/images/hero/My_Account_Desktop.jpg');
  }
}


.navigation#navigation + .wrapper-title {
  margin-top: 95px;
  position: relative;
  @media (min-width: 991px) {
    margin-top: 140px;
  }
}

.wrapper-title {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 100%;
  position: relative;
  @include MQ(M) {
    height: 500px;
  }

  .inner-wrapper {
    height: 100%;
    width: 1400px;
    width: calc(100% - 10px);
    max-width: 100%;
    margin: 0 auto;
    padding: 0 35px;
    position: relative;
    @include MQ(S) {
      padding: 0 15px;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .page-block-title {
      z-index: 1;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      max-width: 100%;
      @include MQ(S) {
        width: 360px;
      }
      @include MQ(M) {
        width: 400px;
      }

      .animation-block-title {
        .page-title {
          font-weight: 900;
          color: $blue-darker;
          text-transform: uppercase;
          max-width: 100%;

          &-offset {
            padding-top: 60px;
          }
        }

        .element-animation {
          max-width: 100%;
          opacity: 0;
          -webkit-animation: fadeInLeft 1s ease forwards;
          animation: fadeInLeft 1s ease forwards;

          &:nth-child(1) {
            -webkit-animation-delay: 0.3333333333s;
            animation-delay: 0.3333333333s;
          }

          &:nth-child(2) {
            -webkit-animation-delay: 0.6666666666s;
            animation-delay: 0.6666666666s;
          }

          &:nth-child(3) {
            -webkit-animation-delay: 0.9999999999s;
            animation-delay: 0.9999999999s;
          }
        }
      }


    }
  }
}


.btn-offset {
  margin-top: 120px;
}

.page-sub-title {
  font-size: 18px;
  color: $blue-darker;
  text-transform: capitalize;
  font-weight: 700;
}

.line-page {
  position: absolute;
  height: 2700px;
  background-color: $yellow-darker;
  top: 0;
  margin-left: -5px;
  z-index: 1;
  opacity: 0;
  transform: rotate(-45deg) translate(235%, -32%);
  animation: fadeLine 1s ease forwards .33s;
  @include MQ(S) {
    width: 290px;
    height: 2030px;
    margin-left: -155px;
  }
  @include MQ(M) {
    width: 355px;
  }
  @include MQ(L) {
    width: 400px;
    margin-left: -100px;
  }

  &:after {
    position: absolute;
    width: 10px;
    right: -30px;
    height: 100%;
    opacity: 0;
    background-color: white;
    content: "";
    animation: whiteLine 1s ease 2s forwards;
  }
}

.bg-interior {
  @include MQ(S) {
    background-image: url(../assets/images/bg-interior-mob.png);
    background-size: cover;
    background-position: top;
  }
  @include MQ(M) {
    background-image: url(../assets/images/interior-bg.jpg);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeLine {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translateX(221%) translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: rotate(-45deg) translateX(221%) translateY(-32%);
  }
}


@keyframes whiteLine {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
import api from "./api";
import {API_CONFIG} from "/src/config/api.config.js";

class CmsService {
	async getContent(cmsOptions) {
		let apiUrl = API_CONFIG.getSitecoreContent() // IE, `/sitecore/sitecore/`

		return await api
			.post(apiUrl, cmsOptions)
			.then(response => {
				return response.data;
			}).catch(e => {
				console.error('Error in cms.service, ', e)
			});
	}

	async getMinistryUpdates(cmsOptions) {
		let apiUrl = API_CONFIG.getSitecoreMinistryUpdates() // IE, `/sitecore/ministry-updates/`

		return await api
			.post(apiUrl, cmsOptions)
			.then(response => {
				if (response && response.status === 200) return response.data
				else if (response && response.status === 204) return 'No more Ministry Updates'
				return false;
			}).catch(e => {
				if (e && e === 'No more Ministry Updates') return "No more Ministry Updates"
				console.error('Error in cms.service, ', e)
			});
	}
}

export default new CmsService();

<template>
  <footer class="footer">
    <div class="inner-wrapper">
      <div class="footer-top">
        <router-link v-if="authStore.isLoggedIn" :to="{ name: 'Friend'}" class="footer-logo">
          <img src="../assets/images/logo-footer.svg" class="img-fluid">
        </router-link>

        <router-link v-else :to="{ name: 'Home'}" class="footer-logo">
          <img src="../assets/images/logo-footer.svg" class="img-fluid">
        </router-link>

        <p class="footer-top-text">{{ footer.fgAddress1 }}<br> {{ footer.fgAddress2 }}</p>
        <div class="footer-logo-partner-wrapper">
          <span class="footer-logo-partner">
            <img
                src="../assets/images/logo-ecfa.svg"
                width="118"
                class="img-fluid"
            />
          </span>
          <span class="footer-logo-partner">
            <img
                src="../assets/images/logo-sot.png"
                width="118"
                class="img-fluid"
            />
          </span>
        </div>
      </div>
      <div class="footer-bottom">
        <p class="footer-bottom-text">{{ footer.fgAddressComplete }}</p>
        <nav class="footer-nav">
          <template v-for="nav in navs">
            <router-link v-if="nav.name === 'My Account'" :to="{ name: 'AccountManagement'}" class="footer-nav-link">
              {{ nav.name }}
            </router-link>
            <router-link v-else :to="nav.link" class="footer-nav-link">{{ nav.name }}</router-link>
          </template>

          <span v-if="!authStore.isLoggedIn" @click="showModal" class="footer-nav-link">{{ footer.fgLogin }}</span>
        </nav>
      </div>
    </div>
    <div class="footer-copy">
      <div class="container-m mx-auto">
        <p class="footer-copy-text">
          {{ footer.fgCopyright }}
          <span> | </span>
          <router-link :to="{ name: 'PrivacyPolicy' }" class="footer-copy-text">
            {{ footer.fgPrivacyPolicy }}
          </router-link>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore";
import {useDesignStore} from "@/stores/designStore"

export default {
  name: "Footer",
  props: {
    footer: {
      type: Object,
      required: true,
    },
    navs: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapStores(useAuthStore, useDesignStore),
  },
  methods: {
    async logout() {
      await this.authStore.logout
      await this.$router.push({name: 'Home'})
    },
    async showModal() {
      try {
        let isAlreadyLoggedIn = null

        isAlreadyLoggedIn = await this.authStore.checkLocalStorageForAuth()

        if (isAlreadyLoggedIn) {
          this.authStore.setUserToLoggedIn()
          this.designStore.setIsShow(false)
          await this.$router.push({name: 'Friend'})
        } else {
          this.designStore.setIsShow(true)
        }

      } catch (e) {
        console.error('Error in Header.vue, ', e)
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/abstracts/_color.scss";
@import "../assets/scss/abstracts/_variables.scss";

.footer {
  position: relative;
  background: $footer-bg;

  > .inner-wrapper {
    width: calc(100% - 30px);
    margin: 0 auto;
    @include MQ(M) {
      width: calc(100% - 50px);
    }
  }

  &-top {
    padding-top: 50px;

    > a.footer-logo {
      max-width: 80%;
      @media (max-width: 991px) {
        margin: 0px auto 50px;
      }
    }
  }

  &-logo-partner {
    transition: opacity 0.25s;
    @media (max-width: 991px) {
      margin: 0 10px;
    }

    &:first-child {
      filter: brightness(0) invert(1);
      margin-right: 15px;
      @media (max-width: 991px) {
        margin: 0 10px;
      }
    }

    img {
      width: 100px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &-nav {
    @include MQ(L) {
      text-align: right;
    }

    &-link {
      font-weight: 500;
      color: white;
      transition: color 0.25s;
      background: transparent;
      border: none;
      cursor: pointer;
      @include MQ(L) {
        margin-left: 25px;
      }

      &:hover {
        color: $yellow-darker;
      }
    }

    button:empty {
      display: none;
    }
  }

  &-bottom {
    padding: 45px 0 40px;

    &-text {
      color: white;
      margin-bottom: 0;
    }
  }

  &-copy {
    background: $footer-copy-bg;

    &-text {
      color: white;
      text-align: center;
      padding: 17px 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 992px) {
  .footer {
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > a.footer-logo {
        width: 250px;
      }
    }
  }
  .footer-top-text {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .footer {
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-copy {
      &-text {
        font-size: 15px;
      }
    }
  }
}


@media screen and (max-width: 991px) {
  .footer {
    &-top {
      text-align: center;

      &-text {
        color: white;
        line-height: 30px;
        margin-bottom: 43px;
      }
    }

    &-logo {
      display: block;
      margin-bottom: 43px;
      width: 250px;
      max-width: 100%;
    }

    &-bottom {
      &-text {
        display: none;
      }
    }

    &-nav {
      &-link {
        display: block;
        text-align: center;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 23px;
        }
      }
    }

    &-copy {
      &-text {
        font-size: 13px;
        line-height: 22px;
        max-width: 240px;
        margin: 0 auto;
      }
    }
  }
}
</style>

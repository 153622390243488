<template>
  <div class="input-container">
    <p class="title-label">
      <slot></slot>
    </p>

    <input
        class="input"
        :class="['input',
          (isDisabled ? 'input-disabled' : ''),
          (which === 'password' || which === 'passwordConfirm'  ? 'input-skinny' : '')
        ]"
        :autocomplete="allowAutofill"
        :disabled="isDisabled"
        :type="type"
        :style="{maxWidth: maxWidth}"
        :placeholder="placeholder"
        :value="modelValue"
        :error="error"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$forceUpdate()"
    >
    <span v-show="which" class="span-inline-flex">
      <i
          v-if="type === 'password'"
          :class="['fa-solid fa-eye',
            (animateIcon ? 'fa-flip' : ''),
            (invertIconColor ? 'fa-inverse' : '')
          ]"
          style="--fa-animation-duration: 1s;"
          @click="emitIconClick('text')"
      />
      <i
          v-if="type === 'text'"
          :class="['fa-solid fa-eye-slash',
            (animateIcon ? 'fa-flip' : ''),
            (invertIconColor ? 'fa-inverse' : '')
          ]"
          style="--fa-animation-duration: 1s;"
          @click="emitIconClick('password')"
      />
    </span>

    <p :class="altCss ? 'input-error-alt' : 'input-error'" v-if="error && error.$error">{{ errorText }}</p>
  </div>
</template>

<script>
export default {
  name: 'Input',
  emits: ['iconClick', 'update:modelValue'],
  props: {
    allowAutofill: {
      type: String,
      required: false,
      default: "on"
    },
    altCss: {
      type: Boolean,
      required: false,
      default: false,
    },
    animateIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    invertIconColor: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: [String, Number],
    },
    error: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    which: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {
    emitIconClick(iconValue) {
      this.$emit('iconClick', {name: this.$props.which, iconValue})
    },
  },
  computed: {
    errorText() {
      return this.error && this.error.$errors.length > 0 ? this.error.$errors[0].$message : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.input-container {
  width: 100%;
  padding: 8px 0px;

}

.title-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  color: $blue-darker;
  margin-bottom: 5px;

  span {
    color: $red;
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;

    span {
      color: $red;
      font-size: 17px;
    }
  }
}

.title-label:empty {
  display: none;
  margin: 0;
}

input:focus[placeholder], [placeholder], *[placeholder] {
  color: #0C2E3C !important;
  background-color: white;
  border-color: #0C2E3C;
}

.input {
  transition: all .33s ease;
  height: 55px;
  width: 100%;
  padding: 18px 15px;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  border: 1px solid $grey;
  border-radius: 3px;
  outline: none;


  @media screen and (max-width: 768px) {
    height: 50px;
    padding: 16px 13px;
    font-size: 13px;
  }
}

input.input-skinny {
  width: 100%;
}

span.span-inline-flex {
  display: inline-flex;
  margin-left: -45px;
}

i.fa-solid {
  margin: 0 auto;
  cursor: pointer;
}

.input-disabled {
  cursor: not-allowed;
  background: #ddd;
}

.input-error {
  font-size: 13px;
  color: $red;
}

.input-error-alt {
  font-size: 16px;
  color: $grey;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}
</style>
<template>
  <header class="header" :class="{'header-fixed': fixed_header,'isTop': isTop}">
    <div class="inner-wrapper" v-bind:class="designStore.getIsLogo ? 'header-logo-flex' : 'header-flex'">
      <div class="header-mob">
        <router-link :to="{ name: 'Home' }" :title="header.fgLinkToHomepage" class="logo-link"/>
      </div>
      <template v-if="designStore.getIsLogo">
        <router-link :to="{ name: 'Friend' }" class="header-logo-desk">
          <img src="./../assets/images/logo-mob.png" v-if="fixed_header" class="logo-black"/>
          <img src="./../assets/images/logo-white.png" v-else class="logo-white"/>
        </router-link>
      </template>
      <nav class="header-nav" v-bind:class="{active: isActive}">
        <div class="header-nav-inner">
          <span class="mobile-close" @click="toggleMenu">{{ header.fgClose }} <span></span></span>
          <h3 class="mobile-heading">{{ header.fgMenu }}</h3>
          <template v-for="nav in navs" :key="nav.name">
            <router-link :to="nav.link" @click="toggleMenu" class="header-nav-link">{{ nav.name }}</router-link>
          </template>
          <span @click="showModal" class="header-nav-link">{{ header.fgLogin }}</span>
        </div>

      </nav>

      <button type="button" class="btn-menu text-uppercase" @click="toggleMenu">
        {{ header.fgMenu }}
        <i class="icons-menu"></i>
      </button>
    </div>
  </header>
</template>

<script>
import {mapStores} from "pinia"
import {useAuthStore} from "@/stores/authStore"
import {useDesignStore} from "@/stores/designStore"

export default {
  name: "Header",
  props: {
    header: {
      type: Object,
      required: true,
    },
    navs: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      fixed_header: false,
      isActive: false,
      lastScrollTop: 0,
      isTop: true
    }
  },
  computed: {
    ...mapStores(useAuthStore, useDesignStore),
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
    async showModal() {
      try {
        this.toggleMenu()

        let isAlreadyLoggedIn = null

        isAlreadyLoggedIn = await this.authStore.checkLocalStorageForAuth()

        if (isAlreadyLoggedIn) {
          this.authStore.setUserToLoggedIn()
          this.designStore.setIsShow(false)
          await this.$router.push({name: 'Friend'})
        } else {
          this.designStore.setIsShow(true)
        }

      } catch (e) {
        console.error('Error in Header.vue, ', e)
      }
    },
    handleScroll(e) {
      let currentScroll = e.target.documentElement.scrollTop;
      if (currentScroll <= 25) {
        this.fixed_header = false;
        this.isTop = true;
        return;
      }
      if (currentScroll > this.lastScrollTop) {
        this.fixed_header = false;
        this.isTop = false;
      } else if (currentScroll < this.lastScrollTop) {
        this.isTop = false;
        this.fixed_header = true
      }
      this.lastScrollTop = e.target.documentElement.scrollTop;
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);

  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>


<style scoped lang="scss">
.header {
  position: fixed;
  width: 100%;
  background: linear-gradient(280deg, #fff, transparent 69%);
  z-index: 999;
  transition: all .33s ease;
  top: -95px;
  left: 0;
  height: 95px;
  transition: all .33s ease;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 992px) {
    min-height: 95px;
  }

  &-fixed {
    background: white;
  }

  &-flex {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 991px) {
    background: white;
  }
  @media (min-width: 992px) {
    top: 0;
  }

  .inner-wrapper {
    width: 1400px;
    max-width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    align-items: center;
    @include MQ(L) {
      width: calc(100% - 20px);
      padding: 0 30px;
    }

    .header-logo-desk {
      display: none;
    }

    .header-mob {
      transition: all .33s ease;
      display: block;
      flex-basis: 250px;
      opacity: 1;
      visibility: visible;

      a.logo-link {
        display: block;
        background-image: url('../assets/images/logo-header.svg');
        width: 100%;
        min-height: 80px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        filter: brightness(100%) grayscale(0) invert(0);
        transition: all .33s ease;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    button {
      cursor: pointer;
      color: $blue-darker;
      @media (min-width: 992px) {
        display: none;
      }
    }
  }

  &.isTop {
    top: 0;

    .header-mob {
      @include MQ(L) {
        body.home & {
          opacity: 0;
          visibility: hidden;
        }
      }

      a.logo-link {
        @include MQ(L) {
          filter: brightness(0%) grayscale(1) invert(1);
        }
      }
    }
  }

  &.header-fixed {
    top: 0;

    .header-mob {
      display: block;
    }
  }

  &:not(.isTop) {
    @include MQ(M) {
      background: white;
    }
  }
}

.header-nav {
  transition: all .33s ease;
  @media (max-width: 991px) {
    position: fixed;
    z-index: 111;
    height: 100vh;
    top: 0;
    transition: all .33s ease;
    right: -100%;
    background: #F9FAFA;
    width: calc(100% - 15px);
    box-shadow: 0 0px 0px rgba(0, 0, 0, .0);
    &-inner {
      padding: 100px 40px 25px;
      width: 100%;
      height: 100%;
      max-height: calc(100vh);
      text-align: right;

      .mobile-heading {
        display: block;
        text-align: end;
        padding: 10px 0px;
        font-weight: 700;
        font-size: 2rem;
      }
    }
    &.active {
      top: 0;
      right: 0;
      box-shadow: 0 5px 10px rgba(0, 0, 0, .4);
    }

  }

  &-link {
    font-weight: 500;
    position: relative;
    color: #0C2E3C;
    background: transparent;
    padding: 0;
    border: none;
    transition: color 0.25s;
    cursor: pointer;
    display: inline-block;
    @media (max-width: 991px) {
      display: block;
      font-weight: 600;
      width: 100%;
      position: relative;


    }
    @include MQ(S) {
      margin-bottom: 20px;
    }
    @include MQ(M) {
      line-height: 45px;
    }

    &:not(:last-child) {
      margin-right: 49px;
      @include MQ(M) {
        margin-right: 30px;
      }
    }

    &:after {
      position: absolute;
      content: "";
      opacity: 0;
      display: block;
      height: 5px;
      max-width: 75px;
      transition: all .33s ease;
      background: $yellow-darker;
      bottom: 0;
      width: 0;
      @media (max-width: 991px) {
        width: 8px;
        content: "";
        position: absolute;
        right: -40px;
        height: calc(100% + 10px);
        top: 50%;
        background-color: $yellow-darker;
        transform: translateY(-50%);
        opacity: 0;
      }
      @media (min-width: 992px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.router-link-active,
    [aria-current] {
      &:after {
        @media (max-width: 991px) {
          opacity: 1;
        }
        @media (min-width: 992px) {
          opacity: 1;
          width: 100%;
        }

      }
    }

    &:hover {
      color: $yellow-darker;
    }
  }
}

.header-logo-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-close {
  position: absolute;
  top: 40px;
  right: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  color: $blue-darker;
  font-size: 15px;
  @media (min-width: 992px) {
    display: none;
  }

  span {
    margin-left: 8px;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 80%;
      height: 2px;
      background: $blue-darker;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.icons-menu {
  width: 23px;
  height: 12px;
  display: flex;
  background: url(../assets/images/icons-menu.svg);
}

.btn-menu {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  padding: 0;

  .icons-menu {
    margin-left: 10px;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

@media screen and (min-width: 992px) {
  .header-mob,
  .mobile-heading {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .header-mob {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .header {
    background: white;
    padding: 12px 0 11px;
  }
  .header-logo-desk {
    display: none;
  }
}


@media screen and (max-width: 475px) {
  .logo-mob {
    max-width: 168px;
  }
}


</style>

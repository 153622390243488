export const inputMixin = {
	methods: {
		handleIconClick(val) {
			if (val && val.name === 'password') {
				this.shouldPasswordIconAnimate = true

				setTimeout(function () {
					this.passwordType = val.iconValue
					this.shouldPasswordIconAnimate = false
				}.bind(this), 500);
			} else if (val && val.name === 'passwordConfirm') {
				this.shouldPasswordConfirmIconAnimate = true

				setTimeout(function () {
					this.passwordConfirmType = val.iconValue
					this.shouldPasswordConfirmIconAnimate = false
				}.bind(this), 500);
			}
		},
	}
}
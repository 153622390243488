import { defineStore } from 'pinia'
import AuthService from "@/services/auth.service"
import TokenService from "@/services/token.service"
import { useCmsStore } from "@/stores/cmsStore"
import { useProductStore } from "@/stores/productStore"
import { useDesignStore } from "@/stores/designStore"

export const useAuthStore = defineStore({
	id: 'auth',
	persist: true,
	state: () => ({
		loggedIn: false,
		userDetails: {
			email: '',
			jwt: '',
			registrationId: '',
			campKey: '',
			cstKey: '',
			firstName: '',
			lastName: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			zip: '',
			country: '',
			newsletter: '',
			phone: '',
			testamentType: '',
			friendType: '',
			totalAmount: '',
			monthlyAmount: '',
			churchName: '',
			churchAdr: '',
			churchCity: '',
			churchState: '',
			churchZip: '',
			churchCountry: '',
			refGideonFirstName: '',
			refGideonLastName: '',
			refGideonAddress: '',
			refGideonCity: '',
			refGideonState: '',
			refGideonZip: '',
			refGideonCountry: '',
			refGideonMemberType: '',
			camp_InternalID: '',
			referrer_InternalID: '',
			church_InternalID: '',
			nS_FriendInternalId: '',
			nS_AddressInternalId: ''
		},
	}),
	getters: {
		isUSAMember: state => state.userDetails.country.toUpperCase() === "UNITED STATES",
		isLoggedIn: state => state.loggedIn,
		getCstKey: state => state.userDetails.cstKey,
		getJwt: state => state.userDetails.jwt,
		getRegistrationId: state => state.userDetails.registrationId,
	},
	actions: {
		async checkLocalStorageForAuth() {
			const currentUser = TokenService.getUser()

			if (currentUser && currentUser.jwt) {
				return await this._checkJwtToken()
			}

			return false
		},
		async forgotPassword(email) {
			return AuthService.forgotPassword(email)
				.then(async res => {
					return Promise.resolve(res)
				},
					error => {
						return Promise.reject(error)
					}
				);
		},
		async login(user) {
			TokenService.removeUser()
			return AuthService.login(user)
				.then(async res => {
					this.$reset()
					if (res && res.cstKey && res.jwt) {
						await this.loginUser(res, false)
						TokenService.setUser(res)
						return Promise.resolve(res)
					}
					return Promise.reject(res)
				},
					error => {
						return Promise.reject(error)
					}
				);
		},
		async loginUser(user, resetUserTokenService) {
			this.loggedIn = true

			if (resetUserTokenService === true) {
				user.jwt = this.getJwt
				TokenService.removeUser()
				TokenService.setUser(user)
			}
			this.userDetails = user

			return true
		},
		async logout() {
			// reset pinia stores:
			this.$reset()
			this._resetCmsStore()
			this._resetDesignStore()
			this._resetProductStore()

			TokenService.removeUser()
		},
		async resetPassword(payload) {
			return AuthService.resetPassword(payload)
				.then(async res => {
					return Promise.resolve(res)
				},
					error => {
						return Promise.reject(error)
					}
				);
		},
		async setUserToLoggedIn() {
			try {
				this.$reset()
				const user = TokenService.getUser()
				return await this.loginUser(user, false)
			} catch (e) {
				console.error('Error in authStore, ', e)
			}
		},


		// *** private actions ***
		async _checkJwtToken() {
			return AuthService.checkJwt()
				.then(res => {
					return Promise.resolve(res)
				},
					error => {
						return Promise.reject(error)
					}
				);
		},

		_resetCmsStore() {
			const cmsStore = useCmsStore()
			cmsStore.$reset()

			return true
		},

		_resetDesignStore() {
			const designStore = useDesignStore()
			designStore.$reset()

			return true
		},

		_resetProductStore() {
			const productStore = useProductStore()
			productStore.$reset()

			return true
		},

	}
})

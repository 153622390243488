/* eslint-disable */

export const API_CONFIG = {
	// API Calls Service
	getStatesAndCountries: () => `/action/states/`,
	inviteNewFriend: () => `/action/invite-friend`,
	registerExistingFriend: () => `/action/register-existing/`,
	registerNewFriend: () => `/action/register/`,
	storeDonation: () => `/store/donation`,
	storePurchase: regId => `/store/cart/purchase/${regId}`,
	updateExistingFriend: cstKey => `/users/${cstKey}`,

	// Auth Service
	authCheckJWT: () => `action/check-jwt`,
	authLogin: () => `/action/login`,
	authRegister: () => `/auth/signup`,
	authForgotPassword: () => `/action/forgot-password`,
	authResetPassword: () => `/action/reset-password`,

	// CMS Service
	getSitecoreMinistryUpdates: () => `/sitecore/ministry-updates/`,
	getSitecoreContent: () => `/sitecore/sitecore/`,

	// Product Service
	getCartItems: regId => `/store/cart/${regId}`,
	getAllProducts: () => `/sitecore/products/`,
	upsertItem: regId => `/store/cart/${regId}`,
}

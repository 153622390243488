import {defineStore} from 'pinia'
import ProductService from "@/services/product.service"
import {SETTINGS_CONFIG} from "@/config/settings.config";

export const useProductStore = defineStore({
	id: 'product',
	persist: true,
	state: () => ({
		cartComplete: [], // complete cart, straight from the APi
		cartTemplate: {},   // only `specialProductId` and `quantity = 0`, no quantities tracked here
		products: [],
	}),
	getters: {
		getAllStoreProducts: state => state.products,
		getAreProductsLoaded: state => state.products.length > 0,
		getCartLength: state => state.cartComplete.length || 0,
		getCartTotalQuantity: state => {
			let quantity = 0
			state.cartComplete.forEach(item => quantity = quantity + item.quantity)
			return quantity
		},
		getCartTemplate: state => state.cartTemplate,
		getCartTotal:
			state => () => state.cartComplete.reduce((sum, item) => sum + (item.quantity * item.price), 0),
		getIsCartEmpty: state => !Array.isArray(state.cartComplete) || state.cartComplete.length === 0,
		getProductById: (state) => {
			return (productId) => state.products.find((product) => product.specialProductId === productId)
		},
		getItemFromCartComplete: (state) => (productId) => state.cartComplete.find((product) => product.specialProductId === productId),
		getRelatedProducts: (state) => productId => state.products.filter(product => product.specialProductId !== productId),
	},
	actions: {
		async getCartItems(registrationId) {
			if (!registrationId) return false

			return ProductService.getCartItems(registrationId, false)
				.then(data => {
						this._setCart(data)

						return Promise.resolve(true)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},

		async getAllProducts(forceReload) {
			if (forceReload === true) {
			} // skip check and just fetch the products again
			else if (this.products && Array.isArray(this.products) && this.products.length > 0) return true

			return ProductService.getAllProducts()
				.then(data => {
						this.products = this._setTitlesForAllProducts(data)
						this._setInitialCartTemplate(data)  // if `cartItems` is empty, initialize it

						return Promise.resolve(true)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},

		async upsertItem(payload, registrationId) {
			if (
				!payload ||
				!payload.SpecialProductId
				|| payload.Quantity < 0
				|| !payload.CstKey
				|| !registrationId
			) return false

			return ProductService.upsertItem(payload, registrationId)
				.then(data => {
						if (data) return Promise.resolve(true)
						return Promise.resolve(false)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},


		// *** private actions ***
		_setCart(cartItems) {
			if (!cartItems || (Array.isArray(cartItems) && cartItems.length < 1)) {
				this.cartComplete = []
				return false
			}

			this.cartComplete = [] // reset

			const imageUrlBase = SETTINGS_CONFIG.storeProductsBaseUrl()

			cartItems.forEach(item => {
				item.imagePath = imageUrlBase + item.specialProductId + ".png"
				this.cartComplete.push(item)
			})

		},

		// Initial setup of cartTemplate, where only the specialProductId and and a default '0' quantity exist
		_setInitialCartTemplate(products) {
			if (products) {
				products.forEach(product => {
					if (!this.cartTemplate[product.specialProductId]) {
						this.cartTemplate[product.specialProductId] = 0
					}
				})
			}
		},

		_setTitlesForAllProducts(products) {
			const imageUrlBase = SETTINGS_CONFIG.storeProductsBaseUrl()

			return products.map(product => {
				product.imagePath = imageUrlBase + product.imagePath

				if (product.description.includes("(Book)")) {
					const splitTitle = product.description.split(" (Book) ")
					product.title = splitTitle[0] + " (Book)"
					product.detailsTitle = splitTitle[0] + " (Book)"
					product.detailsSubtitle = splitTitle[1]
				} else if (product.description.includes("<br>")) {
					const splitTitle = product.description.split("<br>")
					product.detailsTitle = splitTitle[0].trim()
					product.detailsSubtitle = splitTitle[1].trim()
				}
				return product
			})
		}

	}
})
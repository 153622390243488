<template>
  <div class="main">
    <Header :header="header" :navs="navs"/>
    <TitlePage
        v-if="
          $route.name === 'LearnAboutFriends'
          || $route.name === 'LearnAboutGideons'
          || $route.name === 'FAQs'
          || $route.name === 'PrivacyPolicy'
          || $route.name === 'InviteOthers'
          || $route.name === 'ForgotPassword'
          || $route.name === 'RegisterExisting'
          || $route.name === 'ResetPassword'
        "
        :titlePageCms="titlePage"
    />
    <router-view/>
    <Footer :footer="footer" :navs="navs"/>
  </div>
</template>


<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import TitlePage from '@/components/TitlePage'
import {mapStores} from 'pinia'
import {useCmsStore} from "@/stores/cmsStore"

export default {
  name: "Default",
  components: {
    Header, Footer, TitlePage,
  },
  data() {
    return {
      cms: {
        options: {
          Common: true,
          Components: ["h2", "login_modal", "main_section", "view_cart"],
          LanguageCode: "",
          PageName: "home",
          MinistryUpdates: false
        },
        page: {},
      },
      footer: {
        fgAddress1: 'P.O. Box 140800,',
        fgAddress2: 'Nashville, TN 37214',
        fgAddressComplete: 'P.O. Box 140800, Nashville, TN 37214',
        fgBecomeAFriend: 'Become a Friend',
        fgCopyright: '© 2022 The Gideons International. All Rights Reserved.',
        fgInviteOthers: 'Invite Others',
        fgLearnAboutFriends: 'Learn About Friends',
        fgLogin: 'Login',
        fgPrivacyPolicy: 'Privacy Policy',
      },
      header: {
        fgMenu: 'Menu',
        fgLogin: 'Login'
      },
      navs: [
        {name: 'Become a Friend', link: '/joining-friends'},
        {name: 'Learn About Friends', link: '/learn-about-friends'},
        {name: 'Invite Others', link: '/invite-others'},
      ],
      titlePage: {
        fgBackToStore: 'Back to Store'
      },
    }
  },
  computed: {
    ...mapStores(useCmsStore),
  },
  async created() {
    await this.fetchCmsData()
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setAllCmsContent()
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setAllCmsContent() {
      this.setCmsHeaderContent()
      this.setCmsFooterContent()
      this.setCmsNavsContent()
    },
    setCmsHeaderContent() {
      this.header.fgMenu = this.cmsStore.common.header.fgMenu
      this.header.fgLogin = this.cmsStore.common.header.fgLogin
    },
    setCmsFooterContent() {
      this.footer.fgAddress1 = this.cmsStore.common.footer.fgAddress1
      this.footer.fgAddress2 = this.cmsStore.common.footer.fgAddress2
      this.footer.fgAddressComplete = this.cmsStore.common.footer.fgAddressComplete
      this.footer.fgBecomeAFriend = this.cmsStore.common.footer.fgBecomeAFriend
      this.footer.fgCopyright = this.cmsStore.common.footer.fgCopyright
      this.footer.fgInviteOthers = this.cmsStore.common.footer.fgInviteOthers
      this.footer.fgLearnAboutFriends = this.cmsStore.common.footer.fgLearnAboutFriends
      this.footer.fgLogin = this.cmsStore.common.footer.fgLogin
      this.footer.fgPrivacyPolicy = this.cmsStore.common.footer.fgPrivacyPolicy
    },
    setCmsNavsContent() {
      this.navs[0].name = this.cmsStore.common.header.fgBecomeAFriend
      this.navs[1].name = this.cmsStore.common.header.fgLearnAboutFriends
      this.navs[2].name = this.cmsStore.common.header.fgInviteOthers
    },
  },
}
</script>

<style scoped>

</style>


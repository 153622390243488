import {defineStore} from 'pinia'

export const useDesignStore = defineStore({
	id: 'design',
	state: () => ({
		heightHeader: 50,
		heightTitlePage: 0,
		isLogo: true,
		titlePage: {},
		showCart: false,
		showModal: false,
		definitionHeader: false,
	}),
	getters: {
		getIsLogo: state => state.isLogo,
		getDefinitionHeader: state => state.definitionHeader,
		getIsCartVisible: state => state.showCart,
		getIsShow: state => state.showModal,
		getTitlePage: state => state.titlePage,
	},
	actions: {
		setIsLogo(show) {
			this.isLogo = show
		},
		setDefinitionHeader(show) {
			this.definitionHeader = show
		},
		setHeightOfHeader(val) {
			this.heightHeader = val
		},
		setHeightOfTitlePage(val) {
			this.heightTitlePage = val
		},
		setIsShow(show) {
			this.showModal = show
		},
		setIsCartVisible(state) {
			this.showCart = state
		},
		setTitlePage(show) {
			this.titlePage = show
		},
		// *** private actions ***
	}
})
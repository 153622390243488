<template>
  <nav class="navigation" id="navigation">
    <div ref="headerContainer" class="container-l navigation-wrapper">
      <router-link
          class="navigation-item"
          v-for="navPage in navPages" :to="navPage.link"
          :key="navPage.link"
          @click="menuItemClicked"
      >
        {{ navPage.name }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import {mapStores} from "pinia"
import {useDesignStore} from "@/stores/designStore"

export default {
  name: "Nav",
  props: {
    navPages: {
      type: Array,
      required: true,
    }
  },
  computed: {
    ...mapStores(useDesignStore),
  },
  mounted() {
    const headerElement = this.$refs['headerContainer'];
    const height = headerElement.clientHeight ? headerElement.clientHeight : 50
  },
  methods: {
    menuItemClicked() {
      this.$emit('menuItemClicked', true)
    }
  },
}
</script>


<style lang="scss">
.navigation {
  background: $blue-darker;
  z-index: 998;
  width: 100%;

  &-fixed {
    position: fixed !important;
    top: 0;
    transition: top 0.25s;

    + .div {
      margin-top: 47px;
    }
  }

  &-item {
    font-weight: 500;
    position: relative;

    &:after {
      @media (max-width: 991px) {

        right: -45px;
        height: calc(100% + 10px);
        top: 50%;
        transform: translateY(-50%);
        content: "";
        position: absolute;
        background-color: $yellow-darker;
        width: 8px;
        opacity: 0;

      }
      @media (min-width: 992px) {
        content: '';
        display: block;
        height: 5px;
        max-width: 75px;
        width: 0%;
        background: $yellow-darker;
        transition: all .33s ease;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
      }

    }

    &:hover,
    &.router-link-active {
      &:after {
        @media (min-width: 992px) {
          opacity: 1;
          width: 100%;
        }
      }
    }

    &.router-link-active:after,
    &[aria-current] {
      @media (max-width: 991px) {
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .navigation {
    .navigation-wrapper {
      display: flex;
      justify-content: flex-end;
      max-width: none;
      padding: 0 30px;
      width: calc(100% - 20px);
    }

    &-item {
      color: white;
      line-height: 45px;

      &:not(:last-child) {
        margin-right: 47px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .navigation {
    &-item {
      color: $blue-darker;
      display: block;
      text-align: right;
      margin-top: 20px;
      margin-right: 30px;
      font-weight: 600;
      @media (max-height: 700px) {
        margin-top: 15px;
      }
    }
  }
}

</style>
<template>
  <header
      class="header"
      :class="{ 'header-fixed': fixed_header, isTop: isTop }"
  >
    <div
        class="container-l mx-auto header-logo-flex"
        v-bind:class="designStore.getIsLogo ? 'header-logo-flex' : 'header-flex'"
    >
      <div class="header-mob">
        <router-link class="logo-link" v-if="authStore.isLoggedIn" :to="{ name: 'Friend'}"/>
        <router-link class="logo-link" v-else :to="{ name: 'Home'}"/>

        <button type="button" class="btn-menu" @click="toggleMenu">
          {{ menu }}
          <i class="icons-menu"></i>
        </button>
      </div>

      <nav class="header-nav" v-bind:class="{ active: isActive }">
        <span class="mobile-close" @click="toggleMenu"
        >{{ cmsStore.common.header.fgClose }} <span></span
        ></span>
        <div class="mobile-wrapper">
          <div class="header-nav-wrapper">
            <h3 class="mobile-heading">{{ cmsStore.common.header.fgMenu }}</h3>

            <div v-for="nav in navs" :key="nav.name">
              <router-link v-if="nav.link === '/logout'" :to="{}" @click="logout" class="header-nav-link never-active">
                {{ nav.name }}
              </router-link>
              <router-link v-else :to="nav.link" @click="toggleMenu" class="header-nav-link">
                {{ nav.name }}
              </router-link>
            </div>


            <NavCart
                v-if="designStore.getIsCartVisible && productStore.getCartLength > 0"
                :quantity="productStore.getCartLength"
                @click="toggleMenu"
            />

          </div>

          <div class="nav-mob">
            <h3 class="mobile-heading">
              {{ cmsStore.common.header.fgFriendMenu }}
            </h3>

            <Nav :navPages="navPages" @menuItemClicked="toggleMenu"/>

          </div>

        </div>

      </nav>
    </div>
  </header>
  <Nav :navPages="navPages"/>
</template>

<script>
import Nav from "@/components/Nav";
import NavCart from "@/components/NavCart";
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore";
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";
import {useProductStore} from "@/stores/productStore";

export default {
  name: "Header",
  components: {
    Nav, NavCart
  },
  props: {
    menu: {
      type: String,
      required: true,
    },
    navPages: {
      type: Array,
      required: true,
    },
    navs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      AccIsActive: false,
      showMenu: false,
      lastScrollTop: 0,
      isTop: true,
      fixed_header: false,
    };
  },
  methods: {
    async logout() {
      await this.authStore.logout();
      await this.$router.push({name: 'Home'})
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
      this.isActive = !this.isActive
    },
    handleScroll(e) {
      let currentScroll = e.target.documentElement.scrollTop;
      if (currentScroll <= 25) {
        this.fixed_header = false;
        this.isTop = true;
        return;
      }
      if (currentScroll > this.lastScrollTop) {
        this.fixed_header = false;
        this.isTop = false;
      } else if (currentScroll < this.lastScrollTop) {
        this.isTop = false;
        this.fixed_header = true;
      }
      this.lastScrollTop = e.target.documentElement.scrollTop;
    },
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore, useProductStore),
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.header {
  background: white;
  padding: 21px 0 22px;
  z-index: 999;

  &-flex {
    display: flex;
    justify-content: flex-end;
  }

  .logo-mob,
  .logo-desktop img {
    width: 250px;
    @include MQ(S) {
      width: 200px;
    }
  }

  nav.header-nav {
    .mobile-wrapper {
      @media (max-width: 991px) {
        margin-top: 80px;
        max-height: calc(100vh - 135px);
        overflow: auto;
      }
    }
  }
}

.header-nav-wrapper {
  display: flex;

  .my-account {
    position: relative;
    display: inline-block;

    &.active {
      color: $yellow-darker;
    }
  }

  .dropdown_wrapper {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    margin-top: 10px;
    border-radius: 5px;

    .header-link {
      color: $blue-darker;
      padding: 8px 16px;
      text-decoration: none;
      display: block;
      // display: block;
    }
  }

  a {
    margin: 0px 10px;
  }

  span {
    font-weight: 500;
    color: #0c2e3c;
    transition: color 0.25s;
    cursor: pointer;

    &:hover {
      @include MQ(M) {
        color: #f59a1e;
      }
    }
  }

  .header-link {
    border: none;
    display: none;
    // font-size: 20px;
    font-weight: 900;
    padding: 0;
  }
}

.header-nav {
  &-link {
    font-weight: 500;
    color: #0c2e3c;
    transition: color 0.25s ease;

    &:not(:last-child) {
      margin-right: 49px;
    }

    &:hover {
      color: $yellow-darker;
    }
  }
}

.icons-menu {
  width: 23px;
  height: 12px;
  display: flex;
  background: url(../assets/images/icons-menu.svg);
}

.btn-menu {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  padding: 0;

  .icons-menu {
    margin-left: 10px;
  }
}

.header-logo-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: none;
  @include MQ(M) {
    padding: 0 30px;
    width: calc(100% - 20px);
  }
}

.mobile-heading {
  display: none;
}

.mobile-close {
  display: none;
}

@media screen and (min-width: 992px) {
  .btn-menu,
  .nav-mob {
    display: none;
  }
}

.logo-link {
  position: relative;
  display: block;
  width: 250px;
  height: 51px;
  background-image: url("../assets/images/logo-header.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.navigation-wrapper {
  max-width: none;
}

@media screen and (max-width: 991px) {
  .logo-desktop {
    display: none;
  }
  .header-mob {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-nav {
    position: fixed;
    z-index: 111;
    height: 100vh;
    overflow: auto;
    top: 0;
    transition: all 0.33s ease;
    background: #f9fafa;

    .mobile-close {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      i {
        margin-left: 10px;
      }
    }

    .mobile-heading {
      display: block;
      text-align: end;
      padding: 10px 40px;
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 0;
    }

    &-link {
      display: block;
      text-align: end;
      margin: 0 37px 21px 0px;

      &:not(:last-child) {
        margin: 0 37px 21px 0px;
      }
    }

    .header-nav-wrapper {
      padding-top: 20px;
      flex-direction: column;
      @media (max-height: 700px) {
        padding-top: 0;
      }

      a {
        margin: 0 45px 21px 0px;
        font-weight: 700;
        letter-spacing: 0.2px;
        position: relative;
        @media (max-height: 700px) {
          margin-bottom: 15px;
        }

        &:after {
          width: 8px;
          content: "";
          position: absolute;
          right: -45px;
          height: calc(100% + 10px);
          top: 50%;
          background-color: $yellow-darker;
          transform: translateY(-50%);
          opacity: 0;
        }

        &.router-link-active {
          &:after {
            opacity: 1;
          }
        }
      }

      .my-account {
        display: block;
        text-align: end;
        margin: 0 37px 21px 0px;
      }

      .dropdown_wrapper {
        display: contents;
      }
    }

  }
  .header {
    background: white;
    padding: 22px 0 23px;
  }
  .header + .navigation {
    display: none;
  }
  .nav-mob {
    margin-top: 20px;
    // margin-bottom: 50px;
    .navigation {
      background: transparent;
    }

    .navigation-wrapper {
      display: block;
    }

    .navigation-item {
      text-align: center;
      color: $blue-darker !important;
      margin-left: 0;
    }
  }
}

header {
  position: fixed;
  transition: all 0.33s ease;
  top: -100px;
  width: 100%;
  height: 95px;
  @media (min-width: 992px) {
    top: 0;
  }

  .header-mob {
    button.btn-menu {
      color: $blue-darker;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 600;
    }
  }

  nav.header-nav {
    @media (max-width: 991px) {
      transition: all 0.33s ease 0s;
      width: calc(100% - 15px);
      right: calc(-100% + 15px);
      box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
      padding-bottom: 50px;
      .mobile-close {
        background: #f9fafa;
        cursor: pointer;
        padding: 40px 20px 20px 40px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 15px;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;

        span {
          position: relative;
          display: inline-block;
          margin-left: 8px;
          width: 20px;
          height: 20px;

          &:before,
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            background: $blue-darker;
            width: 80%;
            height: 2px;
            border-radius: 50px;
            overflow: hidden;
          }

          &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
      .header-nav-wrapper {
        > div:last-child {
          > div {
            span.my-account {
              display: block;
              border-top: 2px solid $blue-darker;
              padding-top: 25px;
              width: 100%;
              position: fixed;
              width: calc(100% - 45px);
              height: 50px;
              bottom: -50px;
              transition: all 0.1s ease 0s;
              right: 15px;
              margin: 0;
              padding: 15px 50px;
              font-weight: 600;
              text-align: left;
              background: #f9fafa;
              z-index: 2;

              &:before {
                position: absolute;
                content: "";
                left: 5px;
                top: 50%;
                transform: translateY(-50%);
                height: 30px;
                width: 30px;
                display: inline-block;
                background-position: center;
                background-size: contain;
                background-image: url("../assets/images/icons-user.svg");
              }

              span {
                right: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 15px;
                height: 18px;
                display: inline-block;

                &:before,
                &:after {
                  width: 5px;
                  height: 5px;
                  border-radius: 50px;
                  background-color: $yellow-darker;
                  position: absolute;
                  right: 0;
                  content: "";
                  top: 30%;
                  transform: translateY(-50%);
                }

                &:after {
                  top: 70%;
                }
              }
            }

            .dropdown_wrapper {
              right: 15px;
              border-radius: 3px;
              width: calc(100% - 35px);
              bottom: 70px;
              position: fixed;
              display: block;
              box-shadow: none;
              filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.2));

              &:before {
                position: absolute;
                bottom: -15px;
                right: 0;
                content: "";
                width: 15px;
                height: 15px;
                background: #f9f9f9;
                clip-path: polygon(0 0, 100% 0, 100% 100%);
              }

              .header-link {
                padding: 0;
                border-bottom: 1px solid rgba(black, 0.1);

                &:last-child {
                  border-bottom: none;
                }

                .header-nav-link {
                  display: block;
                  margin: 0;
                  text-align: right;
                  padding: 15px 45px 15px 15px;
                  position: relative;
                  font-weight: 600;

                  &[href*="/account"] {
                    &:after {
                      content: "";
                      position: absolute;
                      right: 15px;
                      width: 12px;
                      height: 12px;
                      background-image: url("../assets/images/icons-cog.svg");
                      background-size: contain;
                      background-position: center;
                      background-repeat: no-repeat;
                      top: 50%;
                      transform: translateY(-50%);
                      opacity: 1;
                      background-color: transparent;
                    }
                  }
                }

                span.header-nav-link {
                  &:after {
                    content: "";
                    position: absolute;
                    right: 15px;
                    width: 12px;
                    height: 12px;
                    background-image: url("../assets/images/icons-sign-out-alt.svg");
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
        }
      }
      &.active {
        right: 0;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
        transition: all 0.33s ease 0s, box-shadow 0.33s ease 0.44s;

        .header-nav-wrapper {
          > div:last-child {
            > div {
              .my-account {
                transition: all 0.33s ease 0.33s;
                bottom: 0;
              }
            }
          }
        }
      }
    }
    @media (min-width: 992px) {
      .header-nav-wrapper {
        > div {
          margin-left: 40px;

          > a {
            margin: 0;
            position: relative;

            &.router-link-active {
              &:after {
                position: absolute;
                content: "";
                display: block;
                height: 5px;
                max-width: 75px;
                transition: all .33s ease;
                background: $yellow-darker;
                bottom: -15px;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }

          span.my-account {
            display: flex;
            align-items: center;

            span {
              margin-left: 15px;
              position: relative;
              display: inline;
              width: 11px;
              height: 7px;

              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: $yellow-darker;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(0deg);
                transition: all 0.33s ease;
                clip-path: polygon(0 0, 100% 0, 50% 100%);
              }
            }

            &.active {
              span:before {
                transform: translate(-50%, -50%) rotate(180deg);
              }
            }
          }
        }

        .dropdown_wrapper {
          right: 15px;
          border-radius: 3px;
          width: 170px;

          .header-link {
            padding: 0;
            border-bottom: 1px solid rgba(black, 0.1);

            &:last-child {
              border-bottom: none;
            }

            .header-nav-link {
              display: block;
              margin: 0;
              text-align: right;
              padding: 10px 45px 10px 15px;
              position: relative;

              &[href*="/account"] {
                &:after {
                  transition: all 0.33s ease;
                  content: "";
                  position: absolute;
                  right: 15px;
                  opacity: 0.8;
                  width: 12px;
                  height: 12px;
                  background-image: url("../assets/images/icons-cog.svg");
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }

            span.header-nav-link {
              &:after {
                transition: all 0.33s ease;
                content: "";
                position: absolute;
                right: 15px;
                width: 12px;
                height: 12px;
                opacity: 0.8;
                background-image: url("../assets/images/icons-sign-out-alt.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            &:hover {
              > *:after {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }

    @media (min-width: 991px) and (max-width: 1057px) {
      .header-nav-wrapper {
        > div {
          margin-left: 20px;
        }
      }
    }
  }

  + #navigation {
    position: fixed;
    top: -50px;
    transition: all 0.33s ease;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    height: 45px;
    @media (min-width: 992px) {
      top: 95px;
    }
  }

  &.isTop,
  &.header-fixed {
    top: 0;

    + #navigation {
      top: 95px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    }
  }
}

.never-active:after {
  opacity: 0;
}
</style>

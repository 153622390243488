<template>
  <div
      @click="modalClick"
      v-show="designStore.getIsShow"
      id="modal-container"
      class="modal"
  >
    <div class="modal-wrapper">
      <div class="modal-dialog">
        <button type="button" class="modal-close" @click="hideModal">
          {{ cmsStore.getLoginModal.fgClose }}
        </button>
        <img src="../assets/images/login-img.png" class="img-fluid"/>
        <div class="modal-body">
          <h4 class="modal-title">
            {{ cmsStore.components.login_modal.fgFriendLogin }}
          </h4>
          <p class="modal-sub-title">
            {{ cmsStore.components.login_modal.fgLogInAccess }}
          </p>
          <form class="form-login" @submit.prevent="handleSubmit">
            <Input type="text" v-model.trim="username" :error="v$.username">
              {{ cmsStore.components.login_modal.fgEmailAddress }}
            </Input>
            <Input
                :type="passwordType"
                which="password"
                v-model="password"
                :error="v$.password"
                v-on:keyup.enter="handleSubmit"
                @iconClick="handleIconClick"
                :animateIcon="shouldPasswordIconAnimate"
            >
              {{ cmsStore.components.login_modal.fgPassword }}
            </Input>
            <div v-if="error" class="center-block">
              <p class="login-error">
                <strong>{{ errorMessage }}</strong>
              </p>
            </div>
            <div class="form-login-btn">
              <button
                  type="button"
                  class="btn btn-default"
                  @click="handleSubmit"
              >
                {{ cmsStore.components.login_modal.fgLogin }}
              </button>
            </div>
          </form>
          <div class="center-block">
            <router-link
                :to="{ name: 'ForgotPassword'}"
                @click="hideModal"
                class="btn-link"
            >
              {{ cmsStore.components.login_modal.fgForgotPassword }}
            </router-link>
          </div>
        </div>
        <div class="modal-footer">
          <p class="modal-footer-text">
            {{ cmsStore.components.login_modal.fgDontHaveAnAccount }}
            <span class="block-on-mobile">
              <router-link :to="{ name: 'BecomeAFriend'}" @click="hideModal" class="btn-link">
                {{ cmsStore.components.login_modal.fgBecomeAFriend }}
              </router-link>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input";
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore";
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";
import {useProductStore} from "@/stores/productStore";
import useVuelidate from "@vuelidate/core";
import {email, helpers, maxLength, required} from "@vuelidate/validators";
import {inputMixin} from "@/mixins/inputMixin";

export default {
  name: "LoginModal",
  mixins: [inputMixin],
  components: {Input},
  setup: () => ({v$: useVuelidate({$lazy: false, $autoDirty: true})}),
  data() {
    return {
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["login_modal"],
          LanguageCode: "",
          PageName: "login_modal",
          MinistryUpdates: false,
        },
        page: {},
      },
      formActivelySubmitting: false,
      error: false,
      errorMessage: "",
      submitted: false,
      username: "",
      password: "",
      passwordType: "password",
      shouldPasswordIconAnimate: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore, useProductStore),
  },
  created() {
    this.error = false;
    if (this.authStore.isLoggedIn) {
      this.$router.push({name: 'Friend'})
    }
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;
              this.setAllCmsContents();
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setAllCmsContents() {
    },
    modalClick(event) {
      // if user clicks outside the active modal, this will hide the modal
      if (event.target.id && event.target.id === "modal-container")
        this.hideModal();
    },
    async handleSubmit(e) {
      this.formActivelySubmitting = true;
      this.setErrorMessage(false, "");

      if (this.username && this.password) {
        const isFormValid = await this.v$.$validate();
        let loader = null;

        try {
          loader = this.$loading.show();
          if (isFormValid) {
            await this.authStore
                .login({username: this.username, password: this.password})
                .then(() => {
                      this.hideModal();
                      this.$router.push({name: 'Friend'})
                      this.productStore.getAllProducts(true)
                      this.productStore.getCartItems(this.authStore.getRegistrationId, true)
                    },
                    (error) => {
                      this.setErrorMessage(
                          true,
                          this.cmsStore.components.login_modal
                              .fgErrorUsernameOrPassword
                      );
                      this.loading = false;
                    }
                )
                .catch((e) => {
                  console.error(e);
                });
          } else {
            this.setErrorMessage(
                true,
                this.cmsStore.components.login_modal.fgPleaseFixAboveError
            );
            return false;
          }
        } catch (e) {
          console.error(this.cmsStore.components.login_modal.fgErrorInModal, e);
        } finally {
          loader.hide();
          this.formActivelySubmitting = false;
        } // end  try/catch/finally
      } else {
        this.formActivelySubmitting = false;
        this.setErrorMessage(
            true,
            this.cmsStore.components.login_modal.fgPleaseFixAboveError
        );
      }
    },

    hideModal: function () {
      this.resetModalOptions();
      this.designStore.setIsShow(false);
    },
    resetModalOptions: function () {
      this.formActivelySubmitting = false;
      this.error = false;
      this.loading = false;
      this.message = "";
      this.submitted = false;
    },
    setErrorMessage: function (errorStatus, errorMessage) {
      this.error = errorStatus;
      this.errorMessage = errorMessage;
    },
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage("Email is required", required),
        maxLengthValue: helpers.withMessage(
            "Email cannot be longer than 100 characters",
            maxLength(100)
        ),
        email,
      },
      password: {
        required: helpers.withMessage("A password is required", required),
      },
    };
  },
};
</script>


<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(white, 0.7);
  z-index: 9999999;
  display: flex;
  justify-content: center;

  &-dialog {
    box-shadow: 0 10px 25px rgba(#000, 0.15);
    background: $grey2;
    position: relative;
  }

  .icons-close {
  }

  &-close {
    color: $blue-darker;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    background-color: rgba(white, 0.7);
    transition: all 0.33s ease;

    span {
      position: relative;
      margin-left: 5px;
      width: 10px;
      height: 10px;
      display: inline-block;
      transition: all 0.33s ease;

      &:before,
      &:after {
        content: "";
        transition: all 0.33s ease;
        position: absolute;
        width: 100%;
        height: 2px;
        background: $blue-darker;
        transform: translate(-50%, -50%) rotate(-45deg);
        top: 50%;
        left: 50%;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &:hover {
      background-color: rgba(white, 1);

      span {
        transform: rotate(90deg);

        &:before,
        &:after {
          width: 110%;
        }
      }
    }
  }

  &-body {
    padding: 10px 60px;
    @include MQ(S) {
      padding: 10px 15px;
    }

    h4,
    p {
      margin-bottom: 0;
    }

    form {
      margin: 0;

      .input-container {
        margin-bottom: 0;

        .title-label {
          font-size: 14px;

          + input {
            border: 1px solid #cecece;
            transition: all 0.33s ease;
            height: 45px;
            padding: 0 15px;

            &:focus {
              background: white;
              border-color: $blue;
            }
          }
        }
      }

      .form-login-btn {
        margin: 15px 0 15px;
      }
    }

    .center-block {
      a {
        font-weight: 500;
        transition: all 0.33s ease;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  &-wrapper {
    overflow: auto;
    margin-top: 60px;
    width: 700px;
    @media screen and (min-width: 770px) {
      padding-left: 100px;
      padding-right: 100px;
      min-width: 700px;
    }
    @media screen and (max-width: $sm) {
      margin-top: 20px;
    }
  }

  &-footer {
    border-top: 1px solid #cfcfcf;
    padding: 5px 15px;

    &-text {
      text-align: center;
      margin: 0;
    }
  }

  &-title {
    text-transform: uppercase;
    text-align: center;
  }

  &-sub-title {
    text-align: center;
  }

  .form-login {
    &-btn {
      display: flex;
      justify-content: center;
    }
  }

  .center-block {
    display: flex;
    justify-content: center;
  }

  .login-error {
    font-size: 16px;
    color: $red;
  }
}

@media screen and (min-width: 768px) {
  .modal {
    &-body {
    }

    &-title {
      line-height: 27px;
    }

    &-sub-title {
      line-height: 33px;
    }

    .form-login {
      &-btn {
        margin-top: 30px;
      }
    }

    .btn-link {
      line-height: 30px;
    }

    &-footer {
      &-text {
        line-height: 33px;
      }
    }

    &-close {
      font-size: 15px;
      padding: 15px 25px;
      font-family: $gotham;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    &-dialog {
      margin: 0 15px;
    }

    &-body {
      padding: 20px 15px 34px;
    }

    &-title {
      line-height: 23px;
    }

    &-sub-title {
      line-height: 30px;
    }

    .form-login {
      &-btn {
        margin-top: 24px;
      }
    }

    &-footer {
      &-text {
        line-height: 30px;
      }
    }

    &-close {
      font-size: 12px;
      background-size: 6px;
      background-position: 80% 50%;
      padding: 15px 32px 15px 16px;
    }
  }
}

.block-on-mobile {
  display: initial;
}

@media screen and (max-width: $sm) {
  .block-on-mobile {
    display: block;
  }
}

</style>
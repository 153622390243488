<template>
  <router-link :to="{ name: 'Cart'}">
    <button
        v-show="designStore.getIsCartVisible"
        class="btn btn-view-cart btn-view-cart-empty"
        :class="{ 'btn-view-cart-populated': quantity > 0 }"
    >
      <span class='span-view-cart'>
        <i
            class="fa-solid fa-cart-shopping icon-margin-right"
            :class="{ 'fa-bounce': animateCart }"
            style="--fa-animation-duration: 2s;"
        ></i>{{ cmsStore.getViewCart.fgViewCart }} ({{ quantity }})
      </span>
    </button>
  </router-link>
</template>

<script>

import {mapStores} from 'pinia'
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"

export default {
  name: "ViewCart",
  props: {
    animateCart: {
      type: Boolean,
      required: false,
      default: false,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      position_title: '',
    }
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  methods: {},
  mounted() {

  },
  created() {

  },
  unmounted() {

  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/abstracts/_color.scss';

.btn-view-cart {
  color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 55px;
  z-index: 9;

  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.btn-view-cart-empty:before {
  background-color: $blue;
}

.btn-view-cart-populated:before {
  background-color: $yellow-darker !important;
}

.btn-view-cart:before {
  -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
}

.btn-view-cart:hover:before {
  -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
}

.icon-margin-right {
  margin-right: 12px;
}

.span-view-cart {
  margin-left: 50px;
  @media screen and (max-width: $md) {
    margin-left: 30px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
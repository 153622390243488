<template>
  <router-link v-show="designStore.getIsCartVisible" :to="{ name: 'Cart'}" class="cart-wrapper">
    <span class="fa-layers fa-fw" :class="{ 'inactive-color': quantity && quantity < 1 }">
      <i
          class="fa-solid fa-cart-shopping icon-margin-right"
          style="--fa-animation-duration: 2s;"
      ></i>
      <span class="fa-layers-counter" style=""><strong>{{ quantity }}</strong></span>
    </span>
  </router-link>
</template>

<script>

import {mapStores} from 'pinia'
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"

export default {
  name: "NavCart",
  props: {
    quantity: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      position_title: '',
    }
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  methods: {},
  mounted() {

  },
  created() {

  },
  unmounted() {

  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/abstracts/_color.scss';

@media (max-width: 992px) {
  .cart-wrapper {
    display: block;
    text-align: end;
  }
}


.fa-layers-counter {
  color: $blue-darker;
  margin-left: 8px;
}

.fa-solid {
  color: $blue-darker;
  margin-left: 40px;
}

@media (min-width: 991px) and (max-width: 1057px) {
  .fa-solid {
    margin-left: 20px;
  }
}

.inactive-color * {
  color: white !important;
}
</style>
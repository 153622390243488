import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import setupInterceptors from './services/setupInterceptors';
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGtag from "vue-gtag";

var VueScrollTo = require('vue-scrollto');

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

let app = createApp(App)
app
	.use(pinia)
	.use(router)
	.use(VueLoading, {
		color: "#05171E", // AKA $blue-darkest
		width: 100,
		height: 100,
		zIndex: 99999999,
		'lock-scroll': true,
	})
	.use(VueScrollTo)
	.use(VueToast, {
		position: 'top'
	})
	.use(VueGtag,
		{
			config: {
				id: 'G-HCYB0QS6ME'
			}
		},
	)
	.mount('#app')

setupInterceptors()

/* eslint-disable */

export const SETTINGS_CONFIG = {
	ministryUpdatesBaseUrl: () => `https://media.gideons.org/media/friendsofgideons/photos/`,
	storeProductsBaseUrl: () => `https://media.gideons.org/media/friendsofgideons/store/`,

	routerPublicPages: () => {
		return {
			Home: true,
			BecomeAFriend: true,
			FAQs: true,
			ForgotPassword: true,
			Join: true,
			2024: true,
			Signup: true,
			InviteOthers: true,
			LearnAboutFriends: true,
			LearnAboutGideons: true,
			PrivacyPolicy: true,
			RegisterExisting: true,
			ResetPassword: true,
		}
	}
}

import api from "./api";
import ErrorService from "@/services/error.service"
import {API_CONFIG} from "@/config/api.config";


class AuthService {
	checkJwt() {
		let apiUrl = API_CONFIG.authCheckJWT() // IE, `action/check-jwt`

		return api
			.get(apiUrl)
			.then(res => {
				return res && res.status === 200
			})
			.catch(e => {
				console.log('Error in auth.service, checkJwt(), ', e)
			})
	}

	login({username, password}) {
		let apiUrl = API_CONFIG.authLogin() // IE, `action/login`

		return api
			.post(apiUrl, {
				username,
				password
			})
			.then(response => {
				if (response.status !== 200 || !response.data.cstKey || !response.data.jwt) {
					return false
				}

				return response.data;
			}).catch(function (error) {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
				return error
			});
	}

	register({username, email, password}) {
		let apiUrl = API_CONFIG.authRegister() // IE, `action/signup`

		return api.post(apiUrl, {
			username,
			email,
			password
		});
	}

	async forgotPassword(email) {
		const payLoad = { // Forgot Password functionality uses the 'PasswordForgotReset' model inside the API
			FirstName: "",
			LastName: "",
			Email: email,
		}
		let apiUrl = API_CONFIG.authForgotPassword() // IE, `action/forgot-password`

		return await api
			.post(apiUrl, payLoad)
			.then(response => {
				if (response && response.status === 200) return response.data

				return false;
			}).catch(function (error) {
				return ErrorService.handleErrorFromApi(error, "forgot")
			})
	}

	async resetPassword(payload) {
		let apiUrl = API_CONFIG.authResetPassword() // IE, `action/reset-password`

		return await api
			.post(apiUrl, payload)
			.then(response => {
				if (response && response.status === 200) return response.data

				return false;
			}).catch(function (error) {
				return ErrorService.handleErrorFromApi(error, "reset")
			})
	}
}

export default new AuthService();

import {defineStore} from 'pinia'
import CmsService from "@/services/cms.service";
import {SETTINGS_CONFIG} from "@/config/settings.config";

export const useCmsStore = defineStore({
	id: 'cms',
	persist: true,
	state: () => ({
		common: {
			common: {},
			footer: {},
			header: {},
			nav: {},
			title_page: {}
		},
		components: {
			account_management_form: {},
			become_a_friend: {},
			become_a_friend_form: {},
			checkout_form: {},
			form_template: {},
			giving_form: {},
			h2: {},
			input: {},
			login_modal: {},
			main_section: {},
			select: {},
			view_cart: {},
		},
		languageCode: '',
		ministryUpdates: new Map(),
		ministryUpdatesFeatured: {},
		ministryUpdatesAreMoreAvailable: true,
	}),
	getters: {
		getAcctMgmtForm: state => state.components.account_management_form,
		getChkOutForm: state => state.components.checkout_form,
		getFeaturedArticle: state => state.ministryUpdatesFeatured,
		getLoginModal: state => state.components.login_modal,
		getMinistryUpdateCount: state => state.ministryUpdates.size,
		getMinistryUpdateById: state => itemId => state.ministryUpdates.get(itemId),
		getViewCart: state => state.components.view_cart,
	},
	actions: {
		getMinistryUpdates(cmsOptions) {
			try {
				if (!this.ministryUpdates || Object.getPrototypeOf(this.ministryUpdates) !== Map.prototype) this.ministryUpdates = new Map()

				if (cmsOptions.Page === "0") {  // reset `ministryUpdates` on first page load
					this.ministryUpdates = new Map()
					this.ministryUpdatesAreMoreAvailable = true
				}

				return CmsService.getMinistryUpdates(cmsOptions)
					.then(data => {
							if (data && data === 'No more Ministry Updates') {
								this.ministryUpdatesAreMoreAvailable = false
								return false
							} else if (!data || !Array.isArray(data) || data.length < 1) {
								this.ministryUpdatesAreMoreAvailable = false
								console.error('empty data response, ', data)
								return false
							}
							this._renameMinistryUpdateFields(data)

							if (data && Array.isArray(data) && data.length < cmsOptions.PageSize) {
								this.ministryUpdatesAreMoreAvailable = false
							}

							return Promise.resolve(true)
						},
						error => {
							this.ministryUpdatesAreMoreAvailable = false
							return Promise.reject(error)
						}
					);

			} catch (err) {
				console.error('Error in cmsStore, getMinistryUpdates(), ', err)
			}
		},

		getPageAndOrCommonAndOrComponents(cmsOptions) {
			// fetch the CMS data, retry twice, after 0.5 second intervals, if it fails
			const fetchRetry = async (n) => {
				try {
					return CmsService.getContent(cmsOptions)
						.then(data => {
								if (!data || !data.components) {
									console.error('empty data response, ', data)
									return false
								}
								this._setCmsLanguage(cmsOptions.LanguageCode)
								this._setComponentContent(data.components)

								if (cmsOptions.Common) this._setCmsCommonContent(data.common)

								// we are only returning the 'page' data, not the 'common' or 'components' data
								return Promise.resolve(data.page)
							},
							error => {
								return Promise.reject(error)
							}
						);

				} catch (err) {
					if (n === 1) throw err;
					setTimeout(() => {
						console.log('fetchRetry', n)
						n--
						return fetchRetry(n);
					}, 500)
				}
			};

			return fetchRetry(3).then(r => r) // try this 3 times total, 0.5 seconds in between
		},

		// *** private actions ***
		_formatDate(dateText) {
			let options = {year: 'numeric', month: 'long', day: 'numeric'}; // IE: 'October 2, 2022'

			const year = dateText.substring(0, 4) * 1
			const month = dateText.substring(5, 6) * 1 - 1 // Zero based month index
			const day = dateText.substring(7, 8) * 1

			const datePosted = new Date(year, month, day)
			return datePosted.toLocaleString('en-US', options)
		},
		_renameMinistryUpdateFields(articles) {
			const imageUrlBase = SETTINGS_CONFIG.ministryUpdatesBaseUrl()

			articles.forEach(article => {
				// ministryUpdates is a 'Map' type
				var newArticle = {
					author: article.author,
					date: this._formatDate(article.date_Posted),
					featuredArticle: !!(article && article.featuredArticle && article.featuredArticle === "1"),
					id: article.itemID,
					image: imageUrlBase + article.billboard_Image,
					imageThumb: imageUrlBase + article.billboard_Thumb,
					textBody: article.body_Text || '',
					textPreview: article.preview_Text || '',
					title: article.title,
				}

				if (article.featuredArticle && article.featuredArticle === "1") {
					const originalTitle = newArticle.title.split(',')
					newArticle.mainTitle = originalTitle[0].trim() + ','
					newArticle.subTitle = originalTitle[1].trim()

					this.ministryUpdatesFeatured = newArticle
				} else {
					this.ministryUpdates.set(article.itemID, newArticle)
				}
			})

			return true
		},
		_setCmsCommonContent(commonContent) {
			this.common = commonContent
		},
		_setCmsLanguage(language) {
			this.languageCode = language
		},
		_setComponentContent(data) {
			if (!data) return false

			for (const [key, value] of Object.entries(data)) {
				this.components[key] = value
			}
		},
	}
})
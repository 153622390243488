<template>
  <div class="container">
    <HeaderPage v-if="$route.name !== 'Interior'" :menu="header.fgMenu" :navPages="computedNavPages" :navs="navs"/>
    <Header v-else :header="header" :navs="navs"/>
    <TitlePage v-if="$route.name !== 'GivingPage' && $route.name !== 'CampaignGivingPage' && $route.name !== 'Home'" :titlePageCms="titlePage"/>
    <div :class="['section', { 'section-offset': $route.name !== 'Ministry' && $route.name !== 'GivingPage' && $route.name !== 'CampaignGivingPage' }]">
      <router-view/>
    </div>
    <Footer :footer="footer" :navs="navs"/>
  </div>
</template>

<script>
import HeaderPage from '@/components/HeaderPage'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import TitlePage from '@/components/TitlePage'
import {mapStores} from 'pinia'
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore"

export default {
  name: "Page",
  components: {
    HeaderPage, Footer, TitlePage, Header
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore),
    computedNavPages() {
      if (this.authStore.isUSAMember) return this.navPages
      else {
        return this.navPages.filter(page => page.name !== 'Store')
      }

    },
  },
  data() {
    return {
      cms: {
        loaded: false,
        options: {
          Common: true,
          Components: ["h2", "login_modal", "main_section", "title_page", "view_cart"],
          LanguageCode: "",
          PageName: "home",
          MinistryUpdates: false
        },
        page: {}
      },
      footer: {
        fgAddress1: 'P.O. Box 140800,',
        fgAddress2: 'Nashville, TN 37214',
        fgAddressComplete: 'P.O. Box 140800, Nashville, TN 37214',
        fgBecomeAFriend: 'Become a Friend',
        fgCopyright: '© 2022 The Gideons International. All Rights Reserved.',
        fgInviteOthers: 'Invite Others',
        fgLearnAboutFriends: 'Learn About Friends',
        fgLogin: 'Login',
        fgPrivacyPolicy: 'Privacy Policy',
      },
      header: {
        fgMenu: 'Menu',
        fgLogin: 'Login'
      },
      navPages: [
        {name: 'Friend Home', link: '/friend'},
        {name: 'Ministry Updates', link: '/ministry-update'},
        {name: 'Prayer', link: '/prayer'},
        {name: 'Give', link: '/giving'},
        {name: 'Store', link: '/store'},
        {name: 'Witness', link: '/witness'},
        {name: 'FAQs', link: '/faqs'}
      ],
      navs: [
        {name: 'Learn About Gideons', link: '/learn-about-gideons'},
        {name: 'Invite Others', link: '/invite-others'},
        {name: 'My Account', link: '/account-information'},
        {name: 'Logout', link: '/logout'},
      ],
      titlePage: {
        fgBackToStore: 'Back to Store'
      },
    }
  },
  async created() {
    await this.fetchCmsData()
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setAllCmsContent()
              this.setContentLoaded(true)
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setAllCmsContent() {
      this.setCmsHeaderContent()
      this.setCmsFooterContent()
      this.setCmsNavsContent()
      this.setCmsNavPagesContent()
      this.setCmsTitlePages()
    },
    setCmsHeaderContent() {
      this.header.fgBecomeAFriend = this.cmsStore.common.header.fgBecomeAFriend
      this.header.fgInviteOthers = this.cmsStore.common.header.fgInviteOthers
      this.header.fgLearnAboutFriends = this.cmsStore.common.header.fgLearnAboutFriends
      this.header.fgLogin = this.cmsStore.common.header.fgLogin
      this.header.fgMenu = this.cmsStore.common.header.fgMenu
    },
    setCmsFooterContent() {
      this.footer.fgAddress1 = this.cmsStore.common.footer.fgAddress1
      this.footer.fgAddress2 = this.cmsStore.common.footer.fgAddress2
      this.footer.fgAddressComplete = this.cmsStore.common.footer.fgAddressComplete
      this.footer.fgBecomeAFriend = this.cmsStore.common.footer.fgBecomeAFriend
      this.footer.fgCopyright = this.cmsStore.common.footer.fgCopyright
      this.footer.fgInviteOthers = this.cmsStore.common.footer.fgInviteOthers
      this.footer.fgLearnAboutFriends = this.cmsStore.common.footer.fgLearnAboutFriends
      this.footer.fgLogin = this.cmsStore.common.footer.fgLogin
      this.footer.fgPrivacyPolicy = this.cmsStore.common.footer.fgPrivacyPolicy
    },
    setCmsNavsContent() {
      this.navs[0].name = this.cmsStore.common.header.fgLearnAboutGideons
      this.navs[1].name = this.cmsStore.common.header.fgInviteOthers
      this.navs[2].name = this.cmsStore.common.header.fgMyAccount
      this.navs[3].name = this.cmsStore.common.header.fgLogout
    },
    setCmsNavPagesContent() {
      this.navPages[0].name = this.cmsStore.common.nav.fgFriendHome
      this.navPages[1].name = this.cmsStore.common.nav.fgMinistryUpdates
      this.navPages[2].name = this.cmsStore.common.nav.fgPrayer
      this.navPages[3].name = this.cmsStore.common.nav.fgGive
      this.navPages[4].name = this.cmsStore.common.nav.fgStore
      this.navPages[5].name = this.cmsStore.common.nav.fgWitness
      this.navPages[6].name = this.cmsStore.common.nav.fgFAQs
    },
    setCmsTitlePages() {
      this.titlePage.fgBackToStore = this.cmsStore.common.title_page.fgBackToStore
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/scss/abstracts/_color.scss';

.container {
  background-color: $white1;
}
</style>


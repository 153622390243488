import api from "./api";
import {API_CONFIG} from "@/config/api.config";

class ProductService {
	async getCartItems(registrationId) {
		let apiUrl = API_CONFIG.getCartItems(registrationId) // IE, `/store/cart/123456`

		return await api
			.get(apiUrl)
			.then(response => {
				if (response.status === 200) {
					return response.data;
				} else return []
			}).catch(e => {
				console.error('Error in product.service, ', e)
			});
	}

	async getAllProducts() {
		const payload = {
			"Common": false,
			"Components": [],
			"LanguageCode": "en",
			"PageName": "",
			"MinistryUpdates": false
		}
		let apiUrl = API_CONFIG.getAllProducts() // IE, `/sitecore/products/`

		return await api
			.post(apiUrl, payload)
			.then(response => {
				return response.data;
			}).catch(e => {
				console.error('Error in product.service, ', e)
			});
	}

	async upsertItem(payload, registrationId) {
		let apiUrl = API_CONFIG.upsertItem(registrationId) // IE, `/store/cart/`

		return await api
			.post(apiUrl, payload)
			.then(response => {
				if (response.status === 200) {
					return true;
				} else return false
			}).catch(e => {
				console.error('Error in product.service, ', e)
				return false
			});
	}
}

export default new ProductService();

import axiosInstance from "./api"
import router from '../router'
import TokenService from "./token.service";
import {useAuthStore} from "@/stores/authStore"


const setup = () => {
	const store = useAuthStore()

	axiosInstance.interceptors.request.use(
		config => {
			let token = ""
			const localStorageAccessToken = TokenService.getLocalAccessToken()
			const piniaStoreToken = store.getJwt

			if (localStorageAccessToken) token = localStorageAccessToken
			else if (piniaStoreToken) token = piniaStoreToken

			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	axiosInstance.interceptors.response.use(
		(res) => {
			return res;
		},
		async err => {
			if (err.config.url === "/sitecore/sitecore/" && err.response.status && err.response.status === 500) {
				const errorMessage = err.response.data.message ? err.response.data.message : "Unknown error"
				console.log(errorMessage)
			}

			if (err.config.url === "/sitecore/ministry-updates/" && err.response.status && err.response.status === 404) {
				if (err.response.data && err.response.data === 'Empty response') {
					return Promise.reject("No more Ministry Updates");
				}
			}

			console.log("Error in setupInterceptors, ", err)
			if (err.response && err.response.status && err.response.status === 401) {
				// TODO message the user their session has expired?
				store.logout()
				await this.$router.push({name: 'Home'})
			}

			return Promise.reject(err);
		}
	);
};
export default setup;

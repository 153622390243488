<template>
  <component :is="layout"/>
  <LoginModal/>
  <ViewCart :quantity="productStore.getCartLength" :animateCart="productStore.shouldCartAnimate"/>
</template>

<script>

import DefaultLayout from '@/layouts/DefaultLayout'
import PageLayout from '@/layouts/PageLayout'
import LoginModal from "./components/LoginModal"
import ViewCart from "./components/ViewCart"
import {mapStores} from 'pinia'
import {useAuthStore} from "@/stores/authStore"
import {useProductStore} from "@/stores/productStore"

export default {
  components: {
    DefaultLayout, PageLayout, LoginModal, ViewCart
  },
  data() {
    return {
      layout: null,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useProductStore),
  },
  mounted() {
  },
  methods: {
    logOut() {
      this.authStore.logout()
    }
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "DefaultLayout"
      }
    },
  },
}
</script>

<style lang="scss">
@import './assets/scss/_style.scss';
</style>

class ErrorService {

	handleErrorFromApi(error, apiType) {
		let defaultErrorMessage = "Error during api call"
		if (apiType) {
			defaultErrorMessage = this._setDefaultErrorMessage(apiType)
		}

		if (error.response && typeof error.response.data === 'object' && error.response.data.status === 400) {
			const errData = error.response.data
			if (errData.title && errData.title === 'One or more validation errors occurred.') {
				const errors = []
				for (const [key, value] of Object.entries(errData.errors)) {
					errors.push(value[0])
				}
				if (errors.length > 0) return errors
				else return "One or more validation errors occurred, please review the form"
			}
		} else if (error.response && error.response.data) {
			const errMessage = error.response.data
			if (
				errMessage === "Missing/Invalid email format" ||
				errMessage === "Already a current Friend" ||
				errMessage === "Already a current Gideon" ||
				errMessage === "Already invited recently" ||
				errMessage === "Email not associated with a current Friend" // 'Forgot Password' functionality
			) {
				return errMessage
			}
		}
		console.log(error);

		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.error(error.response.data);
			console.error(error.response.status);
			console.error(error.response.headers);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser
			console.error(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.error('Error', error.message);
		}
		return defaultErrorMessage
	}

	_setDefaultErrorMessage(type) {
		if (type === "donation") {
			return "Error submitting Donation, please try again"
		} else if (type === "invitation") {
			return "Error inviting new Friend, please try again"
		} else if (type === "registration") {
			return "Error during registration, please try again"
		} else if (type === "register-existing") {
			return "Error registering existing Friend, please try again"
		} else if (type === "update") {
			return "Error updating Friend details, please try again"
		} else if (type === "forgot") {
			return "Error sending 'Forgot Password' email, please try again"
		} else if (type === "reset") {
			return "Error resetting password, please try again"
		} else if (type === "purchase") {
			return "Error during Checkout process, please try again"
		}
		return "Error during api call"
	}

}

export default new ErrorService();
